import { Component, OnInit } from '@angular/core';
import { CustomDropdownComponent } from '../../custom-dropdown/custom-dropdown.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared.service';
import { EventBroadcastService } from '../../event-broadcast.service';
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';
import { Subscription } from 'rxjs';
import { log } from 'console';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [CustomDropdownComponent, TranslateModule, CommonModule],
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  dropdownEnglishSources: string[] = [];
  dropdownSources: string[] = [];
  sourceSelection: string = '';
  maxLength: number = 5000;
  currentLength: number = 0;
  textValue: string = '';
  ownSources: boolean = false;

  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};

  constructor(
    private translate: TranslateService, 
    private sharedService: SharedService, 
    private eventBroadcastService: EventBroadcastService, 
    private selectedVariableService: SelectedVariableService
  ) {}

  ngOnInit(): void {
    this.loadTranslations();
    this.sharedService.currentLanguage$.subscribe(() => {
      this.loadTranslations();
    });

    this.selectedVariableService.getSourceBoolean().subscribe(variable => {
      this.ownSources = variable;
    });

    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  onSelectionChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value == 'option1') this.sourceSelection = 'APA';
    if (target.value == 'option2') this.sourceSelection = 'Harvard';
    if (target.value == 'option3') this.sourceSelection = 'MLA';    
  }

  onTextChange(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.textValue = textarea.value;
    this.currentLength = this.textValue.length;
  }

  private loadTranslations() {
    this.translate.get([
      'dropdownEnglishSourcesYes',
      'dropdownEnglishSourcesNo',
      'dropdownSourcesYes',
      'dropdownSourcesNo'
    ]).subscribe(translations => {
      this.dropdownEnglishSources = [
        translations['dropdownEnglishSourcesYes'],
        translations['dropdownEnglishSourcesNo']
      ];
      this.dropdownSources = [
        translations['dropdownSourcesYes'],
        translations['dropdownSourcesNo']
      ];
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
    if (this.isFormValid()) {
      this.selectedVariableService.setFormData('sources', this.formData);
    } else {
      console.warn('Form is not valid');
    }
    this.selectedVariableService.setDropdownSelection('citation Style', this.sourceSelection)
  }

  isFormValid(): boolean {
    return Object.values(this.formData).every(value => value !== undefined && value !== null && value !== '');
  }
}
