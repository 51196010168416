<h2>{{ "bookingData" | translate }}</h2>
<p>{{ "bDP1" | translate }}</p>

<div class="inputSection">
  <div class="orderNumber">
    <span>{{ "orderNumber" | translate }} <span class="redStar">*</span></span>
    <input type="text" (input)="onInputChange($event, 'order Number')" required>
    <p>{{ "bDP2" | translate }}</p>
  </div>
  <div class="email">
    <span>{{ "email" | translate }} <span class="redStar">*</span></span>
    <input type="text" (input)="onInputChange($event, 'E-Mail')" required>
    <p [innerHTML]=" 'bDP3' | translate "></p>
  </div>
</div>
