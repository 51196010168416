<h2>{{ "scopeTitle" | translate }}</h2>

<ul>
    <li>{{ "scopeContent1" | translate }}</li>
    <li>{{ "scopeContent2" | translate }}</li>
    <li>{{ "scopeContent3" | translate }}</li>
</ul>

<div class="inputSection">
    <div>
      <span>{{ "minPagesTitle" | translate }} <span class="redStar">*</span></span>
      <input type="number" (input)="onInputChange($event, 'min pages')" required>
      <p>{{ "minPagesContent" | translate }}</p>
    </div>
    <div>
      <span>{{ "maxPagesTitle" | translate }} <span class="redStar">*</span></span>
      <input type="number" (input)="onInputChange($event, 'max pages')" required>
      <p>{{ "maxPagesContent" | translate }}</p>
    </div>
  </div>