import { Component, OnInit } from '@angular/core';
import { CustomDropdownComponent } from "../../custom-dropdown/custom-dropdown.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared.service';
import { Subscription } from 'rxjs';
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';
import { EventBroadcastService } from '../../event-broadcast.service';

@Component({
  selector: 'app-study',
  standalone: true,
  imports: [CustomDropdownComponent, TranslateModule],
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss']
})
export class StudyComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};
  dropdownOptions: string[] = [];

  constructor(
    private eventBroadcastService: EventBroadcastService, 
    private translate: TranslateService, 
    private sharedService: SharedService,
    private selectedVariableService: SelectedVariableService
  ) {}

  ngOnInit(): void {
    this.loadTranslations();
    this.sharedService.currentLanguage$.subscribe(() => {
      this.loadTranslations();
    });

    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  private loadTranslations() {
    this.translate.get([
      'dropdownOptionsAbiturient',
      'dropdownOptionsBachelor',
      'dropdownOptionsMaster'
    ]).subscribe(translations => {
      this.dropdownOptions = [
        translations['dropdownOptionsAbiturient'],
        translations['dropdownOptionsBachelor'],
        translations['dropdownOptionsMaster']
      ];
    });
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
      this.selectedVariableService.setFormData('Study Data', this.formData);
  }
}
