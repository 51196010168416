import { Component, OnInit } from '@angular/core';
import { CustomDropdownComponent } from "../../custom-dropdown/custom-dropdown.component";
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared.service';
import { EventBroadcastService } from '../../event-broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-structure',
  standalone: true,
  imports: [CustomDropdownComponent, CommonModule, TranslateModule],
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit {
  dropdownStructureBoolean: string[] = [];
  dropdownStructure: string[] = [];
  selectedVariable: boolean = false;
  structuredContent: string[] = [];
  loadedContent: boolean = false;
  countPages: number = 0;
  currentLanguage: string = 'de';
  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};

  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private selectedVariableService: SelectedVariableService,
    private eventBroadcastService: EventBroadcastService
  ) {}

  ngOnInit(): void {
    this.selectedVariableService.getstructureBoolean().subscribe(variable => {
      this.selectedVariable = variable;
    });

    this.sharedService.currentLanguage$.subscribe(language => {
      this.currentLanguage = language;
      this.loadTranslations();
    });
    this.loadTranslations();
    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  private loadTranslations() {
    this.translate.get([
      'dropdownStructureBooleanYes',
      'dropdownStructureBooleanNo',
      'dropdownStructureOption1',
      'dropdownStructureOption2'
    ]).subscribe(translations => {
      this.dropdownStructureBoolean = [
        translations['dropdownStructureBooleanYes'],
        translations['dropdownStructureBooleanNo']
      ];
      this.dropdownStructure = [
        translations['dropdownStructureOption1'],
        translations['dropdownStructureOption2']
      ];
    });
  }

  checkContent(text: string): void {
    const lines = text.split('\n');
    this.structuredContent = lines;
    this.selectedVariableService.contentStructure = lines;
    this.loadedContent = true;
    this.countAllPages();
    console.log('This is the content structure', this.structuredContent);
    console.log('das ist language', this.currentLanguage);
  }

  countAllPages() {
    let mainTopics = this.structuredContent.length - 2;
    this.countPages = Math.ceil(mainTopics * 1.5 + 2.5);
  }

  getStyledChapterLabel(index: number): string {
    const length = this.structuredContent.length;
    if (length === 0) return '';

    let labelKey: string;
    if (index === 0) {
      labelKey = 'introChapter';
    } else if (index === length - 1) {
      labelKey = 'conclusionChapter';
    } else {
      labelKey = 'mainChapter';
    }

    const label = this.translate.instant(labelKey);
    const [title, pages] = label.split(':');
    return `<span class="blue-text">${title}:</span> <span class="grey-text">${pages}</span>`;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
    if (this.isFormValid()) {
      this.selectedVariableService.setFormData('structure Data', this.formData);
    } else {
      console.warn('Form is not valid');
    }
  }

  isFormValid(): boolean {
    return Object.values(this.formData).every(value => value !== undefined && value !== null && value !== '');
  }
}
