<h2>{{ 'topic' | translate }}</h2>

<span>{{ 'optionalTitle' | translate }}</span>
<input type="text" (input)="onInputChange($event, 'topic')">
<p [innerHTML]="('optionalTitleDescription' | translate)"></p>

<span>{{ 'freeField' | translate }} <span class="redStar">*</span></span>
<textarea class="firstTextArea" (input)="onTextChange($event)" name="" id="" [attr.placeholder]="placeholderTopic" (input)="onInputChange($event, 'topic content')"></textarea>

<p>{{ currentLength }} {{ 'fromMax' | translate }} {{ maxLength }} {{ 'characters' | translate }}</p>

<p [innerHTML]="('important' | translate)"></p>

<span>{{ 'howCameToTopic' | translate }}</span>
<textarea class="secondTextArea" (input)="onSecondTextChange($event)" name="" id="" (input)="onInputChange($event, 'why this topic')"></textarea>
<p>{{ secondCurrentLength }} {{ 'fromMax' | translate }} {{ secondMaxLength }} {{ 'characters' | translate }}</p>
<p>{{ 'aiIntegration' | translate }}</p>