<h2>{{ "outlineHeader" | translate }}</h2>

<span>{{ "outlineQuestion" | translate }} <span class="redStar">*</span></span>
<app-custom-dropdown [options]="dropdownStructureBoolean" componentName="structure"></app-custom-dropdown>
<p [innerHTML]="'outlineRecommendation' | translate"></p>

<div *ngIf="selectedVariable">
<span [innerHTML]="'outlineConsideration1' | translate" class="mbt32"></span><br>

    <span style="color: #000000;"><strong>{{ "outlineOption1Headline" | translate }}</strong></span>
    <p [innerHTML]="'outlineOption1' | translate" class="mt0"></p>
    <span style="color: #000000;"><strong>{{ "outlineOption2Headline" | translate }}</strong></span>
    <p [innerHTML]="'outlineOption2' | translate" class="mt0"></p>

<span>{{ "outlineConsiderationQuestion" | translate }}</span>
<app-custom-dropdown [options]="dropdownStructure" componentName="outline considering"></app-custom-dropdown>

<div class="contentTable">{{ "insertOutlineHere" | translate }} <span class="redStar">*</span></div>
<textarea #textareaElement name="" id="" (input)="onInputChange($event, 'structure content')"></textarea>
<p [innerHTML]="'moreInfoAboutOutline' | translate" class="textareaDescription"></p>

<h2>{{ "checkOutlineButton2" | translate }}</h2>
<p>{{ "checkOutlineDescription" | translate }}</p>
<button (click)="checkContent(textareaElement.value)">{{ "checkOutlineButton2" | translate }}</button>

<div *ngIf="loadedContent">
    <h3>{{ "formattedOutlineTitle" | translate }}</h3>
    <div class="structuredContent">
        <table>
            <tr *ngFor="let content of structuredContent; let i = index">
              <td>{{ content }}   =>  </td>
              <td>
                <span [innerHTML]="getStyledChapterLabel(i)"></span>
              </td>
            </tr>
          </table>
    </div>

    <span class="allPages">{{ "totalPageCount" | translate }} <span class="redStar">*</span></span>
    <span> => {{ "pageCount1" | translate }} {{ countPages }} {{ "pageCount2" | translate }}</span>
    <p>{{ "approxPageCount" | translate }}</p>
    <p [innerHTML]="'noticeForOutlineOption2' | translate" ></p>
</div>

</div>