import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private languageSource = new BehaviorSubject<string>('de');
  currentLanguage$ = this.languageSource.asObservable();

  private languageTest = new BehaviorSubject<string>('de');
  actualLanguage$ = this.languageTest.asObservable();

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('de');
    this.translate.use('de').subscribe(() => {
      this.languageSource.next('de');
    });
  }

  changeLanguage(language: string) {
    this.translate.use(language).subscribe(() => {
      console.log(`Language switched to ${language}`);
      this.languageSource.next(language);
    });
  }
}
