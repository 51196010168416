import { Routes } from '@angular/router';

export const routes: Routes = [

    




];
