
  <h2>{{ "studyCourse" | translate }}</h2>

  <span>{{ "writingStyleLevel" | translate }} <span class="redStar">*</span></span>
  <app-custom-dropdown [options]="dropdownOptions" componentName="studyNiveau"></app-custom-dropdown>


  <span>{{ "educationStudy" | translate }} <span class="redStar">*</span></span>
  <input type="text" (input)="onInputChange($event, 'Education')" required/>
  <p>{{ "example" | translate }}</p>

  <span>{{ "departmentName" | translate }}</span>
  <input class="lastInput" type="text" (input)="onInputChange($event, 'school name')"/>
