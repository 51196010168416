<app-header></app-header>

<div class="mainContent">
  <app-headline class="headline"></app-headline>
  <app-booking-data id="data" class="bookingData"></app-booking-data>
  <app-study></app-study>
  <app-volume></app-volume>
  <app-sources id="sources"></app-sources>
  <app-structure id="structure"></app-structure>
  <app-topic id="topic"></app-topic>
  <app-send></app-send>
</div>



<router-outlet></router-outlet>
