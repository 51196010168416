import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { SelectedVariableService } from '../selected-variable.service';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-custom-dropdown',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './custom-dropdown.component.html',
  styleUrl: './custom-dropdown.component.scss'
})
export class CustomDropdownComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() componentName: string = '';
  dropdownOpen = false;
  selectedOption: string | null = null;

  constructor(private selectedVariableService: SelectedVariableService) { }

  ngOnInit(): void {
    // Lade die gespeicherte Auswahl für diese Instanz
    this.selectedOption = this.selectedVariableService.getDropdownSelections()[this.componentName] || null;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option: string, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedOption = option;
    this.selectedVariableService.setDropdownSelection(this.componentName, option);
    if (this.componentName === "structure") {
      if (option == 'Ja' || option == 'Yes' || option == 'Oui' || option == 'Да')
        this.selectedVariableService.setSelectedVariable(true);
      else if (option == 'Nein (empfohlen)' || option == 'No (recommended)' || option == 'Non (recommandé)' || option == 'Нет (рекомендуется)')
        this.selectedVariableService.setSelectedVariable(false);
    }
    if (this.componentName === "have sources") {
      if (option == 'Ja' || option == 'Yes' || option == 'Oui' || option == 'Да') {
        this.selectedVariableService.setSourceVariable(true);
      } else {
        this.selectedVariableService.setSourceVariable(false);
      }
    }
    this.dropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown')) {
      this.dropdownOpen = false;
    }
  }
}
