<h3>{{ "citationStyleSources" | translate }}</h3>

<span>{{ "citationStyle" | translate }} <span class="redStar">*</span></span>
<div class="custom-radio-group">
    <div>
        <label class="custom-radio">
        <input type="radio" name="selection" value="option1" (change)="onSelectionChange($event)">
        <span class="radio-checkmark"></span>
        {{ "apa" | translate }}
        </label>
        <p>{{ "apaExample" | translate }}</p>
    </div>
  
    <div>
        <label class="custom-radio">
            <input type="radio" name="selection" value="option2" (change)="onSelectionChange($event)">
            <span class="radio-checkmark"></span>
            Harvard
        </label>
        <p>{{ "harvardExample" | translate }}</p>
    </div>
  
    <div>
        <label class="custom-radio">
            <input type="radio" name="selection" value="option3" (change)="onSelectionChange($event)">
            <span class="radio-checkmark"></span>
            MLA
        </label>
        <p>{{ "mlaExample" | translate }}</p>
    </div>
</div>

<span>{{ "numberOfSources" | translate }}</span>
<input type="text" (input)="onInputChange($event, 'number of sources')" required>
<p [innerHTML]="'sourceCountInfo' | translate"></p>

<span>{{ "minSourceAge" | translate }}</span>
<input type="text" (input)="onInputChange($event, 'min source age')" >
<p [innerHTML]="'minSourceAgeDesc' | translate"></p>

<span>{{ "englishSources" | translate }} <span class="redStar">*</span></span>
<app-custom-dropdown [options]="dropdownEnglishSources" componentName="english sources"></app-custom-dropdown>
<p [innerHTML]="'englishSourcesDesc' | translate"></p>

<span>{{ "hasSources" | translate }} <span class="redStar">*</span></span>
<app-custom-dropdown [options]="dropdownSources" componentName="have sources"></app-custom-dropdown>
<p [innerHTML]="'hasSourcesDesc' | translate"></p>

<div *ngIf="ownSources">
<span>{{ 'textPlaceSources' | translate }} <span class="redStar">*</span></span>
<textarea 
name="" 
id="" 
placeholder="{{ 'placeholder1' | translate }}

{{ 'placeholder2' | translate }}

{{ 'placeholder3' | translate }}
" (input)="onTextChange($event)" (input)="onInputChange($event, 'my own sources')">
</textarea>
<p>{{ currentLength }} {{ 'fromMax' | translate }} {{ maxLength }} {{ 'characters' | translate }}</p>
</div>