<div class="checkbox-container">
  <input type="checkbox" id="agree" [(ngModel)]="isChecked" />
  <p>{{ "formSubmitted" | translate }} <span class="redStar">*</span></p>
</div>

<button (click)="onSubmit()" [disabled]="!isChecked || !allInputCorrect">
  {{ "send" | translate }}
</button>
<p *ngIf="!isChecked && !allInputCorrect" class="checkTheBox">{{ "confirmFirst" | translate }}</p>


