import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ComponentFormData {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class SelectedVariableService {
  contentStructure: string[] = [];
  private collectedData: { [componentName: string]: ComponentFormData } = {};
  private dropdownSelections: { [componentName: string]: string | null } = {};

  private structureBooleanSubject = new BehaviorSubject<boolean>(false);
  structureBoolean$ = this.structureBooleanSubject.asObservable();

  private sourceBooleanSubject = new BehaviorSubject<boolean>(false);
  sourceBoolean$ = this.sourceBooleanSubject.asObservable();


  constructor() { }

  setFormData(componentName: string, data: ComponentFormData) {
    this.collectedData[componentName] = data;
  }

  getFormData(): { [componentName: string]: ComponentFormData } {
    return this.collectedData;
  }

  setSelectedVariable(param: boolean) {
    this.structureBooleanSubject.next(param);
  }

  getstructureBoolean(): Observable<boolean> {
    return this.structureBoolean$;
  }

  setSourceVariable(param: boolean) {
    this.sourceBooleanSubject.next(param);
  }

  getSourceBoolean(): Observable<boolean> {
    return this.sourceBoolean$;
  }

  setDropdownSelection(componentName: string, selection: string | null) {
    this.dropdownSelections[componentName] = selection;
  }

  getDropdownSelections(): { [componentName: string]: string | null } {
    return this.dropdownSelections;
  }
}
