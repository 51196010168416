import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeadlineComponent } from "./mainContent/headline/headline.component";
import { BookingDataComponent } from "./mainContent/booking-data/booking-data.component";
import { StudyComponent } from "./mainContent/study/study.component";
import { VolumeComponent } from "./mainContent/volume/volume.component";
import { SourcesComponent } from "./mainContent/sources/sources.component";
import { StructureComponent } from "./mainContent/structure/structure.component";
import { TopicComponent } from "./mainContent/topic/topic.component";
import { SendComponent } from "./mainContent/send/send.component";
import { HeaderComponent } from "./header/header.component";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    HeadlineComponent, 
    BookingDataComponent, 
    StudyComponent, 
    VolumeComponent, 
    SourcesComponent, 
    StructureComponent, 
    TopicComponent, 
    SendComponent, 
    HeaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'studyTexter';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.setDefaultLang('de');
    this.translate.use('de');
  }
}
