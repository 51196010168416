import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared.service';
import { Subscription } from 'rxjs';
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';
import { EventBroadcastService } from '../../event-broadcast.service';

@Component({
  selector: 'app-topic',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};
  maxLength: number = 5000;
  secondMaxLength: number = 500;
  currentLength: number = 0;
  secondCurrentLength: number = 0;
  textValue: string = '';
  secondTextValue: string = '';
  placeholderTopic: string = '';

  private languageSubscription?: Subscription;

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private eventBroadcastService: EventBroadcastService, 
    private selectedVariableService: SelectedVariableService
  ) {}

  ngOnInit(): void {
    this.loadTranslation();
    
    this.languageSubscription = this.sharedService.currentLanguage$.subscribe(() => {
      this.loadTranslation();
    });
    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  private loadTranslation(): void {
    this.translate.get('placeholderTopic').subscribe((translation: string) => {
      this.placeholderTopic = translation;
      this.cdRef.detectChanges();
    });
  }

  onTextChange(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.textValue = textarea.value;
    this.currentLength = this.textValue.length;
  }

  onSecondTextChange(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.secondTextValue = textarea.value;
    this.secondCurrentLength = this.secondTextValue.length;
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
    if (this.isFormValid()) {
      this.selectedVariableService.setFormData('Topic Data', this.formData);
    } else {
      console.warn('Form is not valid');
    }

    console.log('This is the data at the end', this.selectedVariableService.getFormData());
    console.log('This is the selections at the end', this.selectedVariableService.getDropdownSelections());
    
  }

  isFormValid(): boolean {
    return Object.values(this.formData).every(value => value !== undefined && value !== null && value !== '');
  }
}
