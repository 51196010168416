import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';
import { EventBroadcastService } from '../../event-broadcast.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-booking-data',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './booking-data.component.html',
  styleUrl: './booking-data.component.scss'
})
export class BookingDataComponent implements OnInit, OnDestroy{
  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};

  constructor(private eventBroadcastService: EventBroadcastService, private selectedVariableService: SelectedVariableService) {}

  ngOnInit(): void {
    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
    if (this.isFormValid()) {
      this.selectedVariableService.setFormData('Booking Data', this.formData);
    } else {
      console.warn('Form is not valid');
    }

    console.log('This is the data at the end', this.selectedVariableService.getFormData());
    console.log('This is the selections at the end', this.selectedVariableService.getDropdownSelections());
    
  }

  isFormValid(): boolean {
    return Object.values(this.formData).every(value => value !== undefined && value !== null && value !== '');
  }
}