import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EventBroadcastService } from '../../event-broadcast.service';
import { HttpClient } from '@angular/common/http';
import { SelectedVariableService } from '../../selected-variable.service';



@Component({
  selector: 'app-send',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule
  ],
  templateUrl: './send.component.html',
  styleUrl: './send.component.scss'
})
export class SendComponent {
  isChecked: boolean = false;
  allInputCorrect: boolean = true;
  constructor(
    private eventBroadcastService: EventBroadcastService,
    private selectedVariableService: SelectedVariableService
  ) { }


  http = inject(HttpClient);

  sendData: any;

  post = {
    endPoint: 'https://studytexter.tobias-wall.de/sendMail.php',
    body: (payload: any) => JSON.stringify(payload),
    options: {
      headers: {
        'Content-Type': 'text/plain',
        responseType: 'text',
      },
    },
  };


  onSubmit(): void {
    if (this.isChecked && this.allInputCorrect) {
      this.eventBroadcastService.triggerEvent();
      this.testResult();
      this.pushResultInData();
      console.log(this.sendData);
      this.http.post(this.post.endPoint, this.post.body(this.sendData), this.post.options)
        .subscribe(response => {
          console.log("Email sent successfully", response);
        }, error => {
          console.error("Error sending email", error);
        });
    } else {
      console.warn("Checkbox is not checked.");
    }
  }



  results = this.selectedVariableService.getFormData();
  selectResults = this.selectedVariableService.getDropdownSelections();

  orderNumber: string = '';
  email: string = '';
  writingLevel: string | null = '';
  education: string = '';
  schoolName: string = '';
  minPages: string = '';
  maxPages: string = '';
  citationStyle: string | null = '';
  countSources: string = '';
  sourceAge: string = '';
  englishSources: string | null = '';
  ownSources: string | null = '';
  sourcesContent: string = '';
  struktureBoolean: string | null = '';
  contentConcidering: string | null = '';
  structureContent: string = '';
  topic: string = '';
  topicContent: string = '';
  whyTopic: string = '';



  send: boolean = false;


  testResult() {
    this.orderNumber = this.results["Booking Data"]["order Number"];
    this.email = this.results["Booking Data"]["E-Mail"];
    this.writingLevel = this.selectResults["studyNiveau"];
    this.education = this.results["Study Data"]["Education"];
    this.schoolName = this.results["Study Data"]["school name"];
    this.minPages = this.results["volume Data"]["min pages"];
    this.maxPages = this.results["volume Data"]["max pages"];
    this.citationStyle = this.selectResults["citation Style"];
    this.countSources = this.results["sources"]["number of sources"];
    this.sourceAge = this.results["sources"]["min source age"];
    this.englishSources = this.selectResults["english sources"];
    this.ownSources = this.selectResults["have sources"];
    this.sourcesContent = this.results["sources"]["my own sources"];
    this.struktureBoolean = this.selectResults["structure"];
    this.contentConcidering = this.selectResults["outline considering"];
    this.topic = this.results["Topic Data"]["topic"];
    this.topicContent = this.results["Topic Data"]["topic content"];
    this.whyTopic = this.results["Topic Data"]["why this topic"];


    this.send = true;

  }

   pushResultInData() {
    if (!this.sendData) {
      this.sendData = {};
    }

    this.sendData.orderNumber = this.orderNumber;
    this.sendData.email = this.email;
    this.sendData.writingLevel = this.writingLevel;
    this.sendData.education = this.education;
    this.sendData.schoolName = this.schoolName;
    this.sendData.minPages = this.minPages;
    this.sendData.maxPages = this.maxPages;
    this.sendData.citationStyle = this.citationStyle;
    this.sendData.countSources = this.countSources;
    this.sendData.sourceAge = this.sourceAge;
    this.sendData.englishSources = this.englishSources;
    this.sendData.ownSources = this.ownSources;
    this.sendData.sourcesContent = this.sourcesContent;
    this.sendData.struktureBoolean = this.struktureBoolean;
    this.sendData.contentConcidering = this.contentConcidering;
    this.sendData.structureContent = this.structureContent;
    this.sendData.topic = this.topic;
    this.sendData.topicContent = this.topicContent;
    this.sendData.whyTopic = this.whyTopic;
  }
}
