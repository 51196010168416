import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EventBroadcastService } from '../../event-broadcast.service';
import { Subscription } from 'rxjs';
import { ComponentFormData, SelectedVariableService } from '../../selected-variable.service';

@Component({
  selector: 'app-volume',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './volume.component.html',
  styleUrl: './volume.component.scss'
})
export class VolumeComponent {
  private subscription: Subscription = new Subscription();
  formData: ComponentFormData = {};

  constructor(private eventBroadcastService: EventBroadcastService, private selectedVariableService: SelectedVariableService) {}

  ngOnInit(): void {
    this.subscription.add(this.eventBroadcastService.trigger$.subscribe(() => this.sendData()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onInputChange(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      this.formData[field] = inputElement.value;
    }
  }

  sendData() {
    if (this.isFormValid()) {
      this.selectedVariableService.setFormData('volume Data', this.formData);
    } else {
      console.warn('Form is not valid');
    }    
  }

  isFormValid(): boolean {
    return Object.values(this.formData).every(value => value !== undefined && value !== null && value !== '');
  }
}
