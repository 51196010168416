<img src="./assets/img/logo.png" alt="" class="logo">
<div class="headerLink" *ngIf="isHeaderOpen || isWideScreen">
    <img (click)="switchLanguage('de'); openHeaderBar();" src="./assets/img/icon/DE.png" alt="">
    <img (click)="switchLanguage('en'); openHeaderBar();" src="./assets/img/icon/EN.png" alt="">
    <img (click)="switchLanguage('fr'); openHeaderBar();" src="./assets/img/icon/FR.png" alt="">
    <img (click)="switchLanguage('ru'); openHeaderBar();" src="./assets/img/icon/RU.png" alt="">
    <a (click)="openHeaderBar()" href="#data">{{ 'data' | translate }}</a>
    <a (click)="openHeaderBar()" href="#sources">{{ 'sources' | translate }}</a>
    <a (click)="openHeaderBar()" href="#structure">{{ 'outline' | translate }}</a>
    <a (click)="openHeaderBar()" href="#topic">{{ 'theme' | translate }}</a>
</div>

<div (click)="openHeaderBar()" class="burger-btn">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>